import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import users from './users/users.js'
import roles from './roles/roles.js'
import permissions from './permissions/permissions.js'
import apps from './apps/apps.js'
import informaticSecurity from './params/informaticSecurity.js'
import appsSettings from './params/appsSettings.js'
import sessions from './sessions/sessions.js'
import scopes from './scopes/scopes.js'
import applications from './applications/applications.js'
import webhooks from './webhooks/webhooks'
import events from './eventsWebhooks/events'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    users,
    roles,
    permissions,
    apps,
    informaticSecurity,
    appsSettings,
    sessions,
    scopes,
    applications,
    webhooks,
    events,
  },
  strict: process.env.NODE_ENV !== 'production',
})
