import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'

const options = {
  buttonsStyling: false,
  customClass: {
    position: 'top-start',
    confirmButton: 'btn btn-success m-1',
    cancelButton: 'btn btn-primary m-1',
    input: 'form-control',
  },
}

Vue.use(VueSweetalert2, options)
