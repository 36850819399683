import axios from 'axios'
import baseInterceptor from './baseInterceptor'
import { getEnvVariable } from '@/helpers'

const AxiosIdentity = axios.create({
  baseURL: getEnvVariable('VUE_APP_CONSOLE_API_URL', process.env.VUE_APP_CONSOLE_API_URL),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})


baseInterceptor(AxiosIdentity)

export default AxiosIdentity
