import AxiosIdentity from "@/utils/axiosIdentity"
import AxiosInstance from "@/utils/axios"

export default {
  namespaced: true,
  state: {
    session: {
      userId: {
        display: true,
        value: '',
        type: 'input',
        label: 'userId'
      },
      userName: {
        display: true,
        value: '',
        type: 'input',
        label: 'userName'
      },
    }

  },

  getters: {
    sessions(state) {
      return state.session
    },
  },
  mutations: {},
  actions: {
    getSessionsV1(_, { id }) {
      return new Promise((resolve, reject) => {
        AxiosInstance
          .get(`/Sessions/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    closeSessionV1(_, { id }) {
      return new Promise((resolve, reject) => {
        AxiosInstance
          .delete(`/Sessions/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    closeAllSessionsV1(_, { id }) {
      return new Promise((resolve, reject) => {
        AxiosInstance
          .delete(`/Sessions/remove-all-sessions​/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getAllSessionsV2(_, { id }) {
      return new Promise((resolve, reject) => {
        AxiosIdentity
          .get(`/sessions/${id}`, { headers: { 'Content-Type': 'application/json' } })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    closeSessionV2(_, { authorizationId }) {
      return new Promise((resolve, reject) => {
        AxiosIdentity
          .post(`/sessions/${authorizationId}/revoke`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    closeAllSessionsV2() {
      return new Promise((resolve, reject) => {
        AxiosIdentity
          .post(`/sessions/revoke_all`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
