import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

function showErrors(errors) {
    for (let i = 0; i < errors.length; i += 1) {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: errors[i].toString(),
          variant: 'danger',
        },
      })
    }
  }

  const baseInterceptor = instance => {
    instance.interceptors.request.use(config => {
      const currentUser = JSON.parse(sessionStorage.getItem('userData'))
      if (currentUser) {
        config.headers.Authorization = `Bearer ${currentUser.access_token}`
      }

      return config
    })

      instance.interceptors.response.use(
        response => {
          if ((response.config.method === 'post' || response.config.method === 'put') && (response.status === 200 || response.status === 201)) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || 'Acción exitosa!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
          return response
        },
        async error => {
          if (error.response.data) {
            if (error.response.data.Message || error.response.data.message) {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.Message || error.response.data.message,
                  variant: 'danger',
                },
              })
            }
            if (error.response.data.errors) {
              const errors = Object.values(error.response.data.errors)
              showErrors(errors)
            } else if (error.response.data.Errors) {
              const errors = Object.values(error.response.data.Errors)
              showErrors(errors)
            }
          }
          return Promise.reject(error)
        },
      )
  }

export default baseInterceptor
