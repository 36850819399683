import AxiosNotificator from "@/utils/axiosNotificator"

export default {
  namespaced: true,
  state: {
  },
  getters: {
    getWebhook(state) {
      return state
    },
  },
  mutations: {
  },
  actions: {
    getWebhooks() {
      return new Promise((resolve, reject) => {
        AxiosNotificator
          .get('/Webhooks')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getWebhookById(_, { id }) {
        return new Promise((resolve, reject) => {
          AxiosNotificator
            .get(`/Webhooks/${id}`)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      updateWebhook(_, { id, data }) {
        return new Promise((resolve, reject) => {
          AxiosNotificator
            .put(`/Webhooks/${id}`, {
                ...data,
            })
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      deleteWebhook(_, { id }) {
        return new Promise((resolve, reject) => {
          AxiosNotificator
            .delete(`/Webhooks/${id}`)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              reject(error)
            })
        })
      },

      validateWebhook(_, { id }) {
        return new Promise((resolve, reject) => {
          AxiosNotificator
            .put(`/Webhooks/${id}/validate`)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      createWebhook(_, { data }) {
        return new Promise((resolve, reject) => {
          AxiosNotificator
            .post('/Webhooks', {
                ...data,
            })
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      generateApiToken(_, { id }) {
        return new Promise((resolve, reject) => {
          AxiosNotificator
            .put(`/webhooks/${id}/regenerate_token`)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              reject(error)
            })
        })
      },
  }
}
