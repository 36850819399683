<template>
  <div v-if="!loading" id="app" class="h-100" :class="[skinClasses]">
    <v-idle
      v-show="false"
      v-if="isUserLoggedIn()"
      :duration="3600"
      :reminders="[60]"
      @idle="logout"
      @remind="openInactivityModal"
    />
    <b-modal
      id="inactivityModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <div>
        <div>{{ $t("sessionExpiring") }}</div>
        <span class="text-primary">{{ timer }}</span>
      </div>
      <template #modal-footer="{ ok, hide }">
        <b-button
          size="sm"
          variant="outline-secondary"
          @click="
            () => {
              hide();
              logout();
            }
          "
        >
          {{ $t("logout") }}
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="
            () => {
              ok();
              cancelIdle();
            }
          "
        >
          {{ $t("keepSesion") }}
        </b-button>
      </template>
    </b-modal>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { initialAbility } from "@/libs/acl/config";
import { provideToast } from "vue-toastification/composition";
import { watch, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { isUserLoggedIn } from "@/auth/utils";

import { useWindowSize, useCssVar } from "@vueuse/core";
import Vidle from "v-idle";
import useJwt from "@/auth/jwt/useJwt";
import authService from "@/utils/authorize-service";

import store from "@/store";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    Vidle,
  },

  data() {
    return {
      loading: false,
      isModalOpen: false,
      timer: 0,
      startTimer: false,
    };
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  watch: {
    timer: {
      handler() {
        if (this.startTimer) {
          setTimeout(() => {
            if (this.timer > 1) {
              this.timer--;
            }
          }, 1000);
          if (this.timer === 1) {
            this.logout();
          }
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();
    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
      isUserLoggedIn,
    };
  },

  created() {
    if (isUserLoggedIn()) {
      this.loading = true;
      this.getApps().finally(() => {});
      this.loading = false;
    }
  },

  methods: {
    ...mapActions("apps", ["getApps"]),
    ...mapMutations("auth", {
      clearCurrentUser: "CLEAR_CURRENT_USER",
    }),
    openInactivityModal(time) {
      this.isModalOpen = true;
      this.$bvModal.show("inactivityModal");
      this.timer = time;
      this.startTimer = true;
    },
    cancelIdle() {
      this.$bvModal.hide("inactivityModal");
      this.startTimer = false;
      this.isModalOpen = false;
      this.timer = 0;
    },
    async logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.clear();
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      this.clearCurrentUser();
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      await authService.signOut();

      // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
    },
  },
};
</script>

<style>
#activityCanvas {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999999;
}
</style>
