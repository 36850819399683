var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticClass:"h-100",class:[_vm.skinClasses],attrs:{"id":"app"}},[(_vm.isUserLoggedIn())?_c('v-idle',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"duration":3600,"reminders":[60]},on:{"idle":_vm.logout,"remind":_vm.openInactivityModal}}):_vm._e(),_c('b-modal',{attrs:{"id":"inactivityModal","no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var hide = ref.hide;
return [_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function () {
            hide();
            _vm.logout();
          }}},[_vm._v(" "+_vm._s(_vm.$t("logout"))+" ")]),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function () {
            ok();
            _vm.cancelIdle();
          }}},[_vm._v(" "+_vm._s(_vm.$t("keepSesion"))+" ")])]}}],null,false,2663934387)},[_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t("sessionExpiring")))]),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.timer))])])]),_c(_vm.layout,{tag:"component"},[_c('router-view')],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }